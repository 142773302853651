import { render, staticRenderFns } from "./lecturerYwList.vue?vue&type=template&id=279a9bfb&scoped=true"
import script from "./lecturerYwList.vue?vue&type=script&lang=js"
export * from "./lecturerYwList.vue?vue&type=script&lang=js"
import style0 from "./lecturerYwList.vue?vue&type=style&index=0&id=279a9bfb&prod&lang=less"
import style1 from "./lecturerYwList.vue?vue&type=style&index=1&id=279a9bfb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279a9bfb",
  null
  
)

export default component.exports