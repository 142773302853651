<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">讲师列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="讲师姓名" class="searchboxItem ci-full">
              <span class="itemLabel">讲师姓名:</span>
              <el-input
                v-model="searchName"
                type="text"
                size="small"
                placeholder="请输入讲师姓名"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="searchIdcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="searchPhone"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
            <div title="讲师状态" class="searchboxItem ci-full">
              <span class="itemLabel">讲师状态:</span>
              <el-select
                v-model="teacherEnable"
                size="small"
                clearable
                placeholder="请选择"
              >
                <el-option label="启用" :value="true"></el-option>
                <el-option label="禁用" :value="false"></el-option>
              </el-select>
            </div>
            <div title="上传机构" class="searchboxItem ci-full">
              <span class="itemLabel">上传机构:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入上传机构"
                clearable
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :span-method="objectSpanMethod"
              :header-cell-style="tableHeader"
              :cell-style="cellbgc"
            >
              <!-- <el-table-column label="序号" align="center" prop="index" /> -->
              <el-table-column
                label="讲师姓名"
                align="left"
                prop="teacherName"
                show-overflow-tooltip
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="teacherIdcard"
                min-width="160"
                show-overflow-tooltip
                fixed
              />
              <el-table-column
                label="手机号"
                align="left"
                 min-width="140"
                prop="teacherPhoneNumber"
              />
              <el-table-column
                label="讲师状态"
                align="left"
                prop="teacherEnable"
              >
                <template slot-scope="scope">
                  {{ scope.row.teacherEnable ? "启用" : "禁用" }}
                </template>
              </el-table-column>
              <el-table-column
                label="资质类型"
                align="left"
                 min-width="140"
                prop="qualificationTypeName"
                show-overflow-tooltip
              />
              <el-table-column
                label="职业证书编号"
                align="left"
                prop="certificateNo"
                min-width="160"
                show-overflow-tooltip
              />
              <el-table-column
                label="证书有效期/发证日期"
                align="left"
                min-width="160"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.qualificationIsForever == true">{{
                    scope.row.qualificationStartDate | momentDate
                  }}</template>
                  <template v-else
                    >{{ scope.row.qualificationStartDate | momentDate }} -
                    {{ scope.row.qualificationEndDate | momentDate }}</template
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="上传机构"
                align="center"
                prop="compName"
                min-width="220"
                show-overflow-tooltip
              />
              <el-table-column label="上传时间" align="left" min-width="100">
                <template slot-scope="scope">{{
                  scope.row.updateTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                  {{ scope.row.qualificationEnable ? "启用" : "禁用" }}
                </template>
              </el-table-column>
              <el-table-column label="使用情况" align="center" width="260" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handleRelatedCourseware(
                        scope.row.teacherQualificationId,
                        '1'
                      )
                    "
                    >关联课件</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handleRelatedCourseware(
                        scope.row.teacherQualificationId,
                        '2'
                      )
                    "
                    >关联课程</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handleRelatedCourseware(
                        scope.row.teacherQualificationId,
                        '3'
                      )
                    "
                    >关联班级</el-button
                  >
                </div>
              </el-table-column>
              <el-table-column label="操作" align="center" width="130px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 15px"
                    size="mini"
                    @click="handleEdit(scope.row.teacherId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 15px"
                    size="mini"
                    @click="downloadNaturalEndowments(scope.row)"
                    >下载资质</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="titleDialog"
      :visible.sync="centerDialogVisible"
      width="80%"
      center
      :before-close="doCloseload"
      class="relation_jg_dialog"
    >
      <div class="flexcc">
        <div class="left table_box" style="width:100%">
          <div>
            <div class="list_table">
              <el-table
                ref="leftMultipleTable"
                :data="leftData"
                tooltip-effect="dark"
                style="width: 100%;margin-bottom:10px"
                size="small"
                :header-cell-style="tableHeader"
                height="450px"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  width="100"
                />
                <el-table-column
                  prop="kpointName"
                  align="left"
                  label="课件名称"
                  show-overflow-tooltip
                  v-if="stu == '1'"
                ></el-table-column>
                <el-table-column
                  prop="courseName"
                  align="left"
                  label="课程名称"
                  show-overflow-tooltip
                  v-if="stu == '2'"
                ></el-table-column>
                <el-table-column
                  prop="projectCode"
                  align="left"
                  label="班级编码"
                  show-overflow-tooltip
                  v-if="stu == '3'"
                ></el-table-column>
                <el-table-column
                  prop="projectName"
                  align="left"
                  label="班级名称"
                  show-overflow-tooltip
                  v-if="stu == '3'"
                ></el-table-column>
                <el-table-column
                  prop="projectState"
                  align="left"
                  label="班级状态"
                  show-overflow-tooltip
                  v-if="stu == '3'"
                >
                  <template slot-scope="scope">
                    {{ $setDictionary("PROJECTSTATE", scope.row.projectState) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="compName"
                  align="center"
                  :label="stu != '3' ? '归属机构' : '机构名称'"
                  show-overflow-tooltip
                ></el-table-column>
                <Empty slot="empty" />
              </el-table>
              <el-pagination
                @size-change="leftSizeChange"
                @current-change="getData2"
                :current-page="apiDataLeft.current"
                :pager-count="5"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="Math.ceil(apiDataLeft.size)"
                layout="total, sizes, prev, pager, next"
                :total="parseInt(apiDataLeft.total)"
                background
                style="margin-bottom:10px;display: flex;justify-content: flex-end;"
                small
                class="relation_page"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "lecturerList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchName: "",
      searchIdcard: "",
      searchPhone: "",
      teacherEnable:'',
      compName:'',
      addlist: [], // 新增列表
      addobj: {
        name: 123,
      },
      spanArr: [],
      leftData: [],
      centerDialogVisible: false,
      pageNumL: 1,
      pageSizeL: 10,
      apiDataLeft: {
        total: 0,
        size: 10,
        current: 1,
      }, //
      stu: "1",
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  watch: {
    searchName: function(val) {
      if (val == "") {
        this.$route.meta.state = false;
      }
    },
    // 缓存之后监听路由 refersh 是否刷新
    $route: function(route) {
      if (route.params.refresh) {
        if (route.params.isAdd) {
          this.searchName = "";
          this.searchIdcard = "";
          this.searchPhone = "";
          this.getData();
        } else {
          this.getData();
        }
      } else {
         this.getData();
      }
    },
  },
  methods: {
    /* 关联课件 */
    handleRelatedCourseware(teacherQualificationId, stu) {
      this.stu = stu;
      this.centerDialogVisible = true;
      this.teacherQualificationId = teacherQualificationId;
      if (stu == "1") {
        this.titleDialog = "关联课件";
        this.getData2();
      }
      if (stu == "2") {
        this.titleDialog = "关联课程";
        this.getData2();
      }
      if (stu == "3") {
        this.titleDialog = "关联班级";
        this.getData2();
      }
    },
    getData2(pageNum = 1) {
      this.pageNumL = pageNum;
      let params = {
        pageNum,
        pageSize: this.pageSizeL,
        qualificationId: this.teacherQualificationId,
      };
      this.$post(
        this.stu == "1"
          ? "/biz/teacher/listKpointPageByQualificationId"
          : this.stu == "2"
          ? "/biz/teacher/listCoursePageByQualificationId"
          : "/biz/teacher/listProjectPageByQualificationId",
        params
      )
        .then((res) => {
          if (res.status == "0") {
            const data = res.data || {};
            this.leftData = data.list;
            this.apiDataLeft = {
              total: data.total,
              size: this.pageSizeL,
              current: this.pageNumL || 1,
            };
            // let leftDataAllList = this.leftDataAll || [];
            // if (leftDataAllList.length) {
            //   this.$nextTick(() => {
            //     (leftDataAllList || []).forEach(el => {
            //       this.leftData.forEach((it, index) => {
            //         if (it.compId == el.compId) {
            //           this.$refs.leftMultipleTable.toggleRowSelection(it, true);
            //         }
            //       });
            //     });
            //   });
            // }
          }
        })
        .catch();
    },
    leftSizeChange(pageSize) {
      this.pageSizeL = pageSize;
      this.getData2();
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        teacherName: this.searchName || "",
        teacherIdcard: this.searchIdcard || "",
        teacherPhoneNumber: this.searchPhone || "",
        teacherEnable: this.teacherEnable,
        compName: this.compName || '',
      };
      this.doFetch({
        url: "/biz/teacher/listPage",
        params,
        pageNum,
      });
    },
    // 修改讲师
    handleEdit(id = "") {
      this.$router.push({
        path: "/web/lecturerYwEdit",
        query: { id, stu: "edit" },
      });
    },
    // 下载资质
    downloadNaturalEndowments(row){
        this.$confirm('请选择讲师资质是否增加水印', "提示", {
            confirmButtonText: "带水印",
            cancelButtonText: "无水印",
            distinguishCancelAndClose: true,
            closeOnClickModal: false
        })
            .then(() => {
                this.$post("/biz/teacher/downloadTeacherQualification", {
                    teacherId: row.teacherId,
                    waterMark: '10'
                }).then((res) => {
                    if (res.status == "0") {
                        for (let item of res.data) {
                            if (!this.downloadItems.includes(item.taskId)) {
                                this.$store.dispatch("pushDownloadItems", item.taskId);
                            } else {
                                this.$message.warning(
                                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                                );
                            }
                        }
                    } else {
                        this.$message.error(re.message);
                    }
                });
            })
            .catch((e) => {
                if(e == 'cancel'){
                    this.$post("/biz/teacher/downloadTeacherQualification", {
                        teacherId: row.teacherId,
                        waterMark: '20'
                    }).then((res) => {
                        if (res.status == "0") {
                            for (let item of res.data) {
                                if (!this.downloadItems.includes(item.taskId)) {
                                    this.$store.dispatch("pushDownloadItems", item.taskId);
                                } else {
                                    this.$message.warning(
                                        "[" + item.fileName + "]已经申请下载,请耐心等待"
                                    );
                                }
                            }
                        } else {
                            this.$message.error(re.message);
                        }
                    });
                }else{
                    return
                }
            });

    },
    // 合并行
    objectSpanMethod({ row, columnIndex }) {
      if (
        columnIndex < 4 ||
        columnIndex == 11 ||
        (columnIndex === 12 && row.row)
      ) {
        // 第一列
        return {
          rowspan: row.row,
          colspan: row.row ? 1 : 0,
        };
      }
    },
    cellbgc({ rowIndex, columnIndex }) {
      if (columnIndex < 4 || columnIndex == 11 || columnIndex === 12) {
        return {
          backgroundColor: "transparent",
        };
      } else {
        if (rowIndex % 2 == 0) {
          return {
            backgroundColor: "rgb(242,247,253)",
          };
        }
      }
    },
    handleListDataBack(data = []) {
      const teachers = {};
      data.forEach((item, index) => {
        if (!teachers[item.teacherId]) {
          teachers[item.teacherId] = [];
        }
        teachers[item.teacherId].push(index);
      });
      for (const key in teachers) {
        const element = teachers[key];
        // if (element.length > 1) {
        element.forEach((item, index) => {
          if (index === 0) {
            data[item].row = element.length;
          } else {
            data[item].row = 0;
          }
        });
        // }
      }
      this.tableData = data;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
// .el-table--border::after, .el-table--group::after{
//   height: 0;
// }
// .el-table--enable-row-transition .el-table__body td:first-child{
//   border-left: 1px solid #EBEEF5;
// }
// .el-table--border, .el-table--group{
//   border: 0;
// }
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
